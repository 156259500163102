
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.cmdb-attr-filter {
  display: flex;

  .esmp-input {
    width: 50%;
  }
}
