
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.cmdb-controller {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__item {
    margin-top: 10px;
  }

  &__ke-search-wrapper {
    margin-bottom: 0 !important;
  }

  &__ke-wrapper {
    max-height: (24px + 15px) * 5;
    overflow: hidden;
    overflow-y: auto;
  }

  &__ke {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-icons {
      display: flex;
      margin-left: auto;
    }
    &-icon {
      width: 24px;
      cursor: pointer;
      margin-left: 5px;
    }

  }

  &__attr-select {
    padding-left: 20px;
  }
}
